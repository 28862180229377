.navbar {
  padding: 32px 120px 0 !important;
  .container-fluid {
    .collapse {
      justify-content: space-between;
      align-items: center;
      .navbar-nav {
        .nav-item {
          cursor: pointer;
          margin-left: 56px;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #ffffff;
          opacity: 0.8;
        }
      }
      .backCall {
        background: #ffffff;
        border-radius: 32px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #292932;
        border: none;
        outline: none;
        padding: 16px 28.3px;
      }
    }
  }
}
