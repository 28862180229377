.home {
  scroll-behavior: smooth;
  .heroSection {
    background: linear-gradient(
        176.38deg,
        #020306 1.58%,
        rgba(2, 3, 6, 0.13) 26.99%,
        rgba(2, 3, 6, 0.57) 88.86%
      ),
      url("../../../public/images/landingPageBG.png"),
      linear-gradient(
        270deg,
        rgba(2, 3, 6, 0.87) 25.59%,
        rgba(2, 3, 6, 0.29) 62.52%,
        rgba(2, 3, 6, 0) 100%
      );
    background-size: cover;
    .herodetails {
      margin: 74px 12.7% 0 54.58%;
      .heroText {
        font-weight: 500;
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -0.01em;
        color: #ffffff;
        width: 100%;
        max-width: 435px;
      }
      .heroSubText {
        font-weight: 500;
        font-size: 24px;
        line-height: 39px;
        color: #ffffff;
        opacity: 0.9;
        width: 100%;
        max-width: 471px;
        margin-top: 28px;
      }
      .contactUs {
        background: #00afe7;
        border-radius: 32px;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: #ffffff;
        border: none;
        outline: none;
        padding: 18px 36px;
        margin-top: 48px;
      }
    }
    .marketingCategory {
      padding: 42px 30px;
      max-width: 800px;
      margin: auto;
      .category {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
        cursor: pointer;
      }
      .selectedCategory.category {
        border-bottom: 4px solid #00afe7;
        padding-bottom: 14px;
      }
    }
  }
  .aboutBlueOcean {
    display: flex;
    justify-content: center;
    gap: 87px;
    padding: 120px 0;
    .aboutDetails {
      .aboutBlueOceanText {
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
        color: #00afe7;
        margin-top: 21px;
      }
      .caretiveAgency {
        font-weight: 500;
        font-size: 43px;
        line-height: 65px;
        letter-spacing: -0.01em;
        color: #09090a;
        width: 100%;
        max-width: 419px;
        margin-top: 10px;
      }
      .description {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #03212b;
        opacity: 0.8;
        width: 100%;
        max-width: 467px;
        margin-top: 28px;
      }
    }
    .aboutImage {
      img {
        width: 100%;
        max-width: 470px;
        min-height: 540px;
      }
    }
  }
  .ourServices {
    .ourServicesText {
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      color: #00afe7;
      text-align: center;
    }
    .services {
      font-weight: 500;
      font-size: 54px;
      line-height: 65px;
      letter-spacing: -0.01em;
      color: #09090a;
      text-align: center;
      margin-top: 10px;
      img {
        width: 100%;
      }
    }
    .allServices {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
      margin-top: 60px;
      .serviceBlock {
        position: relative;
        width: 100%;
        max-width: 584px;
        min-height: 386px;
        .services {
          position: absolute;
          z-index: 11;
          bottom: 32px;
          left: 32px;
          font-weight: bold;
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -0.01em;
          color: #ffffff;
        }

        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          background: linear-gradient(
            180deg,
            rgba(26, 91, 112, 0.57) 0%,
            #1a5b70 100%
          );
          backdrop-filter: blur(41px);
          padding: 40px 37px 84px 32px;
          .serviceName {
            font-weight: bold;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.01em;
            color: #ffffff;
          }
          .learnMore {
            background: #00afe7;
            border-radius: 32px;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            color: #ffffff;
            outline: none;
            border: none;
            padding: 18px 31.2px;
            margin-top: 32px;
          }
        }
        &:hover .services {
          z-index: -1;
        }
        &:hover .overlay {
          opacity: 1;
        }

        .text {
          margin-top: 16px;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #ffffff;
          opacity: 0.8;
        }
      }
    }
  }

  .leaveUsLittleSection {
    margin-top: 120px;
    .leaveUsLittleText {
      font-weight: 500;
      font-size: 54px;
      line-height: 65px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #09090a;
      display: flex;
      flex-direction: column;
    }
    .contactWithUsSection {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      .contactUsForm {
        width: 100%;
        max-width: 518px;
        padding: 68px 72px 72px 64px;
        background: #f6f7f7;
        .formBlock {
          .contactWithUs {
            font-weight: 500;
            font-size: 28px;
            line-height: 28px;
            color: #1d263a;
          }
          form {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            input {
              margin-top: 16px;
              padding: 10px 16px;
              border: 1px solid #ffffff;
              outline: none;
            }
            textarea {
              margin-top: 16px;
              padding: 10px 16px;
              border: 1px solid #ffffff;
              outline: none;
              min-height: 142px;
            }
            button {
              margin-top: 24px;
              padding: 12px 0;
              background: #00afe7;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              outline: none;
              border: none;
              color: #ffffff;
            }
            .error {
              border: 1px solid red;
            }
          }
        }
      }
      .contactUsImage {
        img {
          width: 100%;
          max-width: 554px;
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}
