.loading-wrapper {
	width: 150px;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	flex-direction: column; }

img.loading-icon {
	width: 70px; }


.loading-message {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	color: #000000; }

.overlay {
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%; }

.loader-modal-bg {
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 40px;
	padding-right: 40px;
	background-color: #fff;
	border-radius: 20px;
	margin: auto;
	width: 230px; }
