.footer {
  padding: 36px 120px;
  align-items: center;
  justify-content: space-between;
  background-color: #03212B;
  margin-top: 120px;

  .thirdPerson {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;

    a {
      color: #00afe7
    }

  }

  .socialMedia {
    img {
      margin: 0 12px;
    }
  }
}