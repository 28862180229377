.base-modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 20;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    &.show {
        visibility: visible;
        opacity: 1; }

    .overlay {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5); }

    .base-modal__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100vh;
        overflow: auto;
        padding: 2rem; }

    .base-modal__content {
        z-index: 11;
        position: relative;
        display: block; }

    .modal-title {
        font-size: 1.25rem; }

    .modal-content {
        background-color: var(--color-secondary);
        border-radius: 2rem;
        box-shadow: 0px 0px 10px 5px #000000;
        border: 1px solid #000000;
        display: inline-block;
        width: 21.875rem;
        min-height: 25.8125rem;
        padding: 1.25rem; }

    .close-button {
        position: absolute;
        top: 1.125rem;
        right: 1.125rem;
        padding: 0.5rem;
        cursor: pointer;
        background-color: transparent;
        border: none; } }


@media ( max-width: 480px) {
    .base-modal {
        .modal-content {
            width: 100%;
            box-sizing: border-box;
            min-height: 20.8125rem; }
        .modal-title {
            font-size: 1.01rem; }
        .close-button {
            padding: 0.25rem; } } }
